import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import { Flex } from '../common/CommonStyledComponents';
import { style } from './style';

export const InviteToTestHeader = ({ text, inviteAccept, inviteReject }) => {
  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        paddingBottom: '40px',
      }}
    >
      <Typography sx={style.headerText}>{text}</Typography>
      <Flex>
        <Button variant='contained' color='primary' sx={style.button} onClick={inviteAccept}>
          {'Sign Me Up!'}
        </Button>
        <Button
          variant='outlined'
          sx={{
            ...style.button,
            marginLeft: '12px',
          }}
          onClick={inviteReject}
        >
          {'Sorry, Can’t This Time'}
        </Button>
      </Flex>
    </Flex>
  );
};

InviteToTestHeader.propTypes = {
  text: PropTypes.string.isRequired,
  inviteAccept: PropTypes.func.isRequired,
  inviteReject: PropTypes.func.isRequired,
};
